(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/form.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/form.js');

'use strict';

svs.banner_common = svs.banner_common || {};
svs.banner_common.banner_helpers = svs.banner_common.banner_helpers || {};
const isServer = typeof exports === 'object';
let log;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  log = trinidad.logger('banner_common: banner-helpers-form');
} else {
  log = svs.core.log.getLogger('banner_common: banner-helpers-form');
}
svs.banner_common.banner_helpers.Form = function ($el) {
  const $parentNode = $el === null || $el === void 0 ? void 0 : $el.parent();
  let formLinkForm;
  let formComponent = null;
  if (svs.utils.url.queryString.showForm) {
    if (!svs.core.userSession.isAnonymous()) {
      formLinkForm = window.location.search.substr('?').split('showForm=')[1];
      setupForm();
    } else {
      removeFormIdParam();
    }
  }

  $parentNode === null || $parentNode === void 0 || $parentNode.on('click', '.js-form-btn', showFormDetails);
  function addFormIdParams(formLinkForm) {
    const newUrl = "".concat(window.location.href, "?showForm=").concat(formLinkForm);
    return window.history.replaceState({
      path: newUrl
    }, '', newUrl);
  }
  function removeFormIdParam() {
    return window.history.replaceState({
      path: location.pathname
    }, '', location.pathname);
  }

  function showFormDetails(ev) {
    ev.stopPropagation();
    formLinkForm = this.parentElement.dataset.formLinkForm;
    if (!formLinkForm) {
      log.info('No available forms');
    } else if (isLoggedIn(ev, formLinkForm)) {
      setupForm();
    }
  }

  function isLoggedIn(ev, formLinkForm) {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL) && !svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      addFormIdParams(formLinkForm); 
      svs.components.customer_login.login();
    } else {
      return true;
    }
  }

  function setupForm() {
    async.series([loadForm, fetchFormData, reveal.bind(null, {
      clientX: 211,
      clientY: 359
    })], err => {
      if (!err) {
        formComponent.render();
        removeFormIdParam();
      }
    });
  }

  function loadForm(callback) {
    svs.ui.loader.show();
    svs.core.module.load('/cl/components/form', null, error => {
      svs.ui.loader.hide();
      if (error) {
        svs.components.Alert('Kunde inte ladda formuläret - Försök igen!', {
          time: svs.components.Alert.Time.SHORT,
          type: svs.components.Alert.Type.ERROR
        }).show();
        callback('err');
      } else {
        formComponent = new svs.components.form.Form();
        callback(null);
      }
    }, false);
  }

  function fetchFormData(callback) {
    formComponent.fetchData(formLinkForm, callback);
  }

  function reveal(e, callback) {
    svs.components.revealAnimation.runRevealAnimation(e, callback);
  }
};

 })(window);