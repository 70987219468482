(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/images.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/images.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
let logger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('banner_common: banner-helpers');
} else {
  logger = svs.core.log.getLogger('banner_common: banner-helpers');
}
const getMediaQueries = (mediaQueries, key) => {
  if (mediaQueries) {
    return mediaQueries[key];
  }
};
const formatImages = (model, key) => {
  const initialValue = {};
  const imagesArray = model === null || model === void 0 ? void 0 : model.images;
  const mediaQueries = model.layoutConfig.mediaQueries;
  if (imagesArray && imagesArray.length) {
    const images = imagesArray === null || imagesArray === void 0 ? void 0 : imagesArray.reduce((image, item) => _objectSpread(_objectSpread({}, image), {}, {
      [item[key]]: _objectSpread(_objectSpread(_objectSpread({}, item), {}, {
        dominantColor: '#ccc',
        aspectRatio: {
          width: item.width,
          height: item.height
        },
        loadingLazy: false
      }, getMediaQueries(mediaQueries === null || mediaQueries === void 0 ? void 0 : mediaQueries[0], item[key])), {}, {
        altText: item === null || item === void 0 ? void 0 : item.altText,
        title: item === null || item === void 0 ? void 0 : item.title
      })
    }), initialValue);
    if (images.mobileImage_BANNER_MOBILE || images.mobileImage) {
      if (images.mobileImage && !images.mobileImage_BANNER_MOBILE) {
        images.mobileImage_BANNER_MOBILE = images.mobileImage;
      }
      delete Object.assign(images, {
        productImage_BANNER_MOBILE: images.mobileImage_BANNER_MOBILE
      }).mobileImage_BANNER_MOBILE;
    }
    return images;
  }
  logger.warn('The imagearray is empty');
};
if (isServer) {
  module.exports = {
    formatImages
  };
} else {
  svs.banner_common.banner_helpers.formatImages = formatImages;
}

 })(window);